import App, { AppContext, AppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import { Router } from 'next/router';
import React, { useEffect } from 'react';

import * as Sentry from '@sentry/node';
import cssVars from 'css-vars-ponyfill';
import * as firebase from 'firebase/app';
import moment from 'moment';
import NProgress from 'nprogress'; // nprogress module
// eslint-disable-next-line import-helpers/order-imports
import $ from 'jquery';

import 'firebase/analytics';
import 'firebase/auth';
import '../styles/index.scss';
import 'react-sweet-progress/lib/style.css';
import Cookies from 'universal-cookie';
import verifyWhetherDisplayBlackBackgroundCampaign from 'utils/verifyWhetherDisplayBlackBackgroundCampaign';

import CartContextProvider from 'contexts/cartContext';
import RecaptchaContextProvider from 'contexts/RecaptchaContext';
import { IDonationCampaign } from 'models/donationCampaign';
import { IShoppingCart } from 'models/shoppingCart';
import { IUser } from 'models/user';
import { DonationCampaignClientService } from 'services/donationCampaignClientService';
import { fetcher } from 'services/httpRequestor';
import { SettingClientService } from 'services/SettingClientService';
import { ShoppingCartClient } from 'services/shoppingCartClient';
import { UserService } from 'services/userService';

import AppContextProvider from '../contexts/appContext';
import AuthContextProvider from '../contexts/authContext';
import * as gtag from '../utils/gtag';
import 'moment-timezone';

const { publicRuntimeConfig } = getConfig();

if (process.browser) {
  firebase.initializeApp(publicRuntimeConfig.firebase);
}

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (
  publicRuntimeConfig.sentryDNS &&
  process.env.NEXT_PUBLIC_ENVIRONMENT_TYPE !== 'PreProduction'
) {
  Sentry.init({
    // enabled: publicRuntimeConfig.NextPublicEnvironmentType === 'Production',
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT_TYPE,
    dsn:
      SENTRY_DSN ||
      'https://1326ec59945d41f78b26e9aafbf2b3ad@o124009.ingest.sentry.io/5426541',
    release: publicRuntimeConfig.version,
    tracesSampler: samplingContext => {
      // Ignore health check logs
      if (
        samplingContext?.transactionContext?.name === 'GET /api/health_check' ||
        samplingContext?.transactionContext?.name === '/' ||
        samplingContext?.transactionContext?.name === 'TypeError'
      ) {
        return false;
      }
      return true;
    },
  });
}

interface IProps extends AppProps {
  host: string;
  initialProps: {
    user: IUser | undefined;
    userCart: IShoppingCart | undefined;
    isServer: boolean;
    donationCampaigns: IDonationCampaign[];
    featuredDonationCampaignSlug?: string;
    facebookFoundationPage?: string;
    instagramFoundationUser?: string;
    twitterFoundationUser?: string;
    youtubeFoundationChannel?: string;
    festiveInstagramPost?: string;
    paypalThreshold?: string;
  };
}

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeError', () => NProgress.done());
Router.events.on('routeChangeComplete', (url: string) => {
  gtag.pageview(url);
  NProgress.done();
});

// This default export is required in a new `pages/_app.js` file.
function MyApp({ Component, pageProps, initialProps, host }: IProps) {
  useEffect(() => {
    if (window) {
      // Because of Hubspot embedded iframe
      window.$ = $;
      window.jQuery = $;
    }
  }, []);

  cssVars({
    // Options...
  });

  return (
    <AppContextProvider serverInitialState={initialProps}>
      <AuthContextProvider serverInitialState={initialProps}>
        <RecaptchaContextProvider>
          <CartContextProvider serverInitialState={initialProps}>
            <Head>
              <link rel="manifest" href="/manifest.json" />
              <meta charSet="utf-8" />
              <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
              <meta
                name="viewport"
                content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=5"
              />
              <meta name="theme-color" content="red" />
              <meta name="mobile-web-app-capable" content="yes" />
              <meta
                name="apple-mobile-web-app-title"
                content="Application Title"
              />
              <meta name="apple-mobile-web-app-capable" content="yes" />
              <meta
                name="apple-mobile-web-app-status-bar-style"
                content="default"
              />
              <meta name="msapplication-navbutton-color" content="red" />
              <meta name="msapplication-TileColor" content="red" />
              <meta
                name="msapplication-TileImage"
                content="ms-icon-144x144.png"
              />
              <meta name="msapplication-config" content="browserconfig.xml" />
              <meta name="application-name" content="Application Name" />
              <meta name="msapplication-tooltip" content="Tooltip Text" />
              <meta name="msapplication-starturl" content="/" />
              <meta name="msapplication-tap-highlight" content="no" />
              <meta name="full-screen" content="yes" />
              <meta name="browsermode" content="application" />
              <meta name="nightmode" content="enable/disable" />
              <meta name="layoutmode" content="fitscreen/standard" />
              <meta name="imagemode" content="force" />
              <meta name="screen-orientation" content="portrait" />
              <title>Surf Life Saving Foundation</title>
            </Head>
            <Component host={host} {...pageProps} />
            <script
              src={`https://maps.googleapis.com/maps/api/js?key=${publicRuntimeConfig.googlePlacesAPIKey}&libraries=places`}
            />
            <script
              src={`${publicRuntimeConfig.westpacUrl}/quickstream-api-1.0.min.js`}
            />
          </CartContextProvider>
        </RecaptchaContextProvider>
      </AuthContextProvider>
    </AppContextProvider>
  );
}

const cookieContainsApiToken = (appContext: AppContext) => {
  const cookies = new Cookies(appContext.ctx.req?.headers.cookie);
  return !!cookies.get('api_token');
};

const getAuthorisedUser = async (appContext: AppContext) => {
  if (process.browser || !cookieContainsApiToken(appContext)) return undefined;

  try {
    const cookies = appContext.ctx.req?.headers.cookie;
    const userService = new UserService(fetcher(cookies));

    const { data, status } = await userService.getAuthorisedUser();

    return status === 200 ? data : undefined;
  } catch (ex) {
    console.warn(
      'token might be expired, let firebase client solve it in the callback handler. Check next exception',
    );
    console.error(ex);
    return undefined;
  }
};

const getUserCart = async (appContext: AppContext) => {
  if (process.browser || !cookieContainsApiToken(appContext)) return undefined;

  const cookies = appContext.ctx.req?.headers.cookie;

  const shoppingCartServerService = new ShoppingCartClient(fetcher(cookies));
  const { status, data } = await shoppingCartServerService.getMyCart();

  return status === 200 ? data : undefined;
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const donationCampaignService = new DonationCampaignClientService(fetcher());
  const settingService = new SettingClientService(fetcher());
  const [donationCampaigns, settings, user, userCart] = await Promise.all([
    donationCampaignService.getAllDonationsCampaigns(),
    settingService.getAllSettings(),
    getAuthorisedUser(appContext),
    getUserCart(appContext),
  ]);

  const paypalThreshold = settings?.paypalThreshold?.value;
  const applepayEnabled = settings?.applepayEnabled?.value;
  const googlepayEnabled = settings?.googlepayEnabled?.value;
  const paypalEnabled = settings?.paypalEnabled?.value;
  const menu404Structure = settings?.menu404Structure?.jsonValue || [];
  const menuStructure = settings?.menuStructure?.jsonValue || [];
  const footerMenuStructure = settings?.footerMenuStructure?.jsonValue || [];
  const xmasBanner = settings?.xmasBanner?.jsonValue || {};
  const blackBackgroundCampaignConfiguration =
    settings?.blackBackgroundCampaignConfiguration?.jsonValue || {};
  const featuredDonationCampaignSlug =
    settings?.featuredDonationCampaignSlug?.value;
  const featuredGiftGivingCampaignSlug =
    settings?.featuredGiftGivingCampaignSlug?.value;
  const productListCampaignSuggestedItems =
    settings?.productListCampaignSuggestedItems?.value;
  const facebookFoundationPage = settings?.facebookFoundationPage?.value;
  const instagramFoundationUser = settings?.instagramFoundationUser?.value;
  const twitterFoundationUser = settings?.twitterFoundationUser?.value;
  const youtubeFoundationChannel = settings?.youtubeFoundationChannel?.value;
  const festiveTreeSlug = settings?.festiveTreeSlug?.value;
  const festiveInstagramPost = settings?.festiveInstagramPost?.value;
  const animationsS3Url = settings?.animationsS3Url.value;
  const thankYouPageFeaturedCampaignSlug =
    settings?.thankYouPageFeaturedCampaignSlug?.value;

  let host;
  if (process.browser) {
    host = window.location.origin;
  } else {
    host = `https://${appContext?.ctx?.req?.headers.host}`;
  }
  return {
    ...appProps,
    host,
    initialProps: {
      user,
      userCart,
      donationCampaigns,
      paypalThreshold,
      applepayEnabled,
      googlepayEnabled,
      paypalEnabled,
      menuStructure,
      menu404Structure,
      featuredDonationCampaignSlug,
      featuredGiftGivingCampaignSlug,
      thankYouPageFeaturedCampaignSlug,
      productListCampaignSuggestedItems,
      facebookFoundationPage,
      instagramFoundationUser,
      twitterFoundationUser,
      youtubeFoundationChannel,
      festiveTreeSlug,
      festiveInstagramPost,
      blackBackgroundCampaignConfiguration,
      displayBlackBackgroundCampaign: blackBackgroundCampaignConfiguration
        ? verifyWhetherDisplayBlackBackgroundCampaign(
            blackBackgroundCampaignConfiguration.startDate,
            blackBackgroundCampaignConfiguration.endDate,
            moment().tz('Australia/Sydney').toISOString(),
          )
        : false,
      footerMenuStructure,
      xmasBanner,
      isServer: !!appContext.ctx.req,
      animationsS3Url,
    },
  };
};

export default MyApp;
